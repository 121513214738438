/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// Need jQuery? Install it with "yarn add jquery", then uncomment to import it.
import $ from "jquery";
import "slick-carousel";

// any CSS you import will output into a single css file (app.css in this case)
import "./scss/app.scss";

///////////////////////////////////////////////////////////////// COOKIES NOTICE

function isScriptLoaded(url) {
    const scripts = document.getElementsByTagName("script");
    for (let i = scripts.length; i--; ) {
        if (
            scripts[i].src === url ||
            scripts[i].getAttribute("data-tag") === url
        ) {
            return true;
        }
    }
    return false;
}

function allowCookies() {
    const env = $("body").data("env");
    const gtmcode = $("body").data("gtmcode");

    if (env !== "dev") {
        const analyticsCodes = [
            {
                url: "https://www.googletagmanager.com/gtm.js?id=",
                code: gtmcode,
                isGtm: true,
            },
        ];

        const standardScripts = [
            // {
            //     url: '#identifier',
            //     isInline: true,
            //     //code: "alert();"
            // }
        ];

        $.each(analyticsCodes, function (index, value) {
            const urlToLoad = value.url + value.code;

            if (!isScriptLoaded(urlToLoad)) {
                const s = document.createElement("script");
                s.setAttribute("src", urlToLoad);
                s.async = true;
                document.head.appendChild(s);

                window.dataLayer = window.dataLayer || [];

                if (value.isGtm) {
                    window.dataLayer.push({
                        "gtm.start": new Date().getTime(),
                        event: "gtm.js",
                    });
                } else {
                    window.gtag =
                        window.gtag ||
                        function () {
                            window.dataLayer.push(arguments);
                        };

                    window.gtag("consent", "default", {
                        ad_storage: "granted",
                        analytics_storage: "granted",
                    });

                    window.gtag("js", new Date());
                    window.gtag("config", value.code);
                }
            }
        });

        $.each(standardScripts, function (index, value) {
            const urlToLoad = value.url;

            if (!isScriptLoaded(urlToLoad)) {
                const s = document.createElement("script");

                if (!value.isInline) {
                    s.setAttribute("src", urlToLoad);
                } else {
                    s.setAttribute("data-tag", urlToLoad);
                    const i = document.createTextNode(value.code);
                    s.appendChild(i);
                }

                document.head.appendChild(s);
            }
        });
    }
}

$(function () {
    if (localStorage.getItem("popState") === "shown") {
        $("#cookies-bar").hide();
        $("#cookies-bar-tab").show();
    }

    if (localStorage.getItem("acceptedCookies") === "true") {
        allowCookies();
    }

    $("#cookies-bar-tab").on("click", function (e) {
        $("#cookies-bar").fadeIn();
        $("#cookies-bar-tab").fadeOut();
    });

    $("#cookies-bar--close--accept").on("click", function (e) {
        localStorage.setItem("popState", "shown");
        localStorage.setItem("acceptedCookies", true);
        allowCookies();

        $("#cookies-bar").fadeOut();
        $("#cookies-bar-tab").fadeIn();
    });

    $("#cookies-bar--close--reject").on("click", function (e) {
        localStorage.setItem("popState", "shown");
        localStorage.setItem("acceptedCookies", false);

        $("#cookies-bar").fadeOut();
        $("#cookies-bar-tab").fadeIn();
    });
});

///////////////////////////////////////////////////////////////// END COOKIES NOTICE

///////////////////////////////////////////////////////////////// TOP MENU

// selections
const $siteHeader = $(".site-header");
const $navbarList = $(".navbar__list");

const $subMenu = $navbarList.find("ul.top > li > ul");
const $parentLink = $subMenu.prev();

const $subSubMenu = $navbarList.find("ul.top > li > ul > li > ul");
const $subParentLink = $subSubMenu.prev();

const $hamburgerBtn = $(".hamburger");
const $navLogo = $("#nav-logo");
// search
const $searchButton = $(".nav-search__button");
const $searchInput = $(".nav-search__input");
const $searchInputMobile = $(".nav-search__input--mobile");
const $searchButtonMobile = $(".nav-search__button--mobile");

// mobile only links
// find links in submenus that have the same href as their parent link
// add class mobile-only-link to their parent list item - hide them on desktop
// parent links are not clickable on mobile as they open the submenu so need additional links to those pages in the submenus
// these should only display on mobile because the parent links are clickable on desktop
$(() => {
    let parentHrefs = [];

    $subMenu.each(function () {
        parentHrefs.push($(this).prev().attr("href"));
    });

    parentHrefs.forEach((href) => {
        $subMenu.each(function () {
            $(this)
                .find('a[href="' + href + '"]')
                .parent()
                .addClass("mobile-only-link");
        });
    });
});

$(() => {
    let subParentHrefs = [];

    $subSubMenu.each(function () {
        subParentHrefs.push($(this).prev().attr("href"));
    });

    subParentHrefs.forEach((href) => {
        $subSubMenu.each(function () {
            $(this)
                .find('a[href="' + href + '"]')
                .parent()
                .addClass("mobile-only-link");
        });
    });
});

// stop propagation logic
const stopPropagationElements = [
    $subMenu,
    $subSubMenu,
    $parentLink,
    $subParentLink,
    $searchButton,
    $searchButtonMobile,
    $searchInput,
    $searchInputMobile,
    $siteHeader,
];

stopPropagationElements.forEach((element) => {
    element.click(function (e) {
        e.stopPropagation();
    });
});

function animateOutSearchInput() {
    if (screen.width > 991) {
        $searchInput.animate(
            {
                width: 0,
                opacity: 0,
            },
            300,
            function () {
                $searchInput.css("display", "none");
            }
        );
    } else {
        $searchInputMobile.animate(
            {
                width: 0,
                opacity: 0,
            },
            300,
            function () {
                $searchInput.css("display", "none");
            }
        );

        $navLogo.animate(
            {
                opacity: "1",
            },
            200
        );
    }
}

function animateInSearchInput() {
    $searchInput.css("display", "block");
    if (screen.width > 991) {
        $searchInput.animate(
            {
                width: 300,
                opacity: 1,
            },
            300
        );
    } else {
        $searchInputMobile.css("display", "block");
        $searchInputMobile.animate({
            // 'width': $(document).width() - 30,
            width: "70vw",
            opacity: 1,
        });

        $navLogo.animate(
            {
                opacity: "0",
            },
            200
        );
    }
}

// change nav background color
function makeNavTransparent() {
    // nav is never transparent on homepage
    if (
        window.location.pathname !== "/" &&
        window.location.pathname !== "/home"
    ) {
        $siteHeader.removeClass("site-header--blue");
        screen.width <= 991 && closeMobileMenu();
        // hideSubMenu()
    }
}

function makeNavBlue() {
    $siteHeader.addClass("site-header--blue");
}

$(() => {
    if (
        window.location.pathname === "/" ||
        window.location.pathname === "/home"
    ) {
        makeNavBlue();
    }
});

// search bar on the top nav - animate in and out
$searchButton.on("click", function () {
    animateInSearchInput();
});

$searchButtonMobile.on("click", function () {
    animateInSearchInput();
});

// add carat to parent links
$parentLink.addClass("angle-after");
$subParentLink.addClass("angle-after");
// animate in subnavs

// .active-submenu class is added to open submenus/removed from closed submenus so that open submenus can be closed when a new one is opened
// this needs to be the case particularly on desktop where the submenus overlap

$parentLink.on("mouseenter", function (e) {
    console.log("enter parent", $(this));
    $(".active-submenu").prev().attr("aria-expanded", "false");
    $(".active-submenu")
        .removeClass("active-submenu")
        .animate(
            {
                opacity: 0,
            },
            0,
            function () {
                $(this).css("display", "none");
            }
        );

    $(this).next().addClass("active-submenu");

    $(".active-submenu").css("display", "flex").animate(
        {
            opacity: 1,
        },
        0
    );

    $(this).attr("aria-expanded", "true");
});

$subParentLink.on("mouseenter", function (e) {
    console.log("enter sub", $(this));
    $(".active-subsubmenu").prev().attr("aria-expanded", "false");
    $(".active-subsubmenu")
        .removeClass("active-subsubmenu")
        .animate(
            {
                opacity: 0,
            },
            0,
            function () {
                $(this).css("display", "none");
            }
        );

    $(this).next().addClass("active-subsubmenu");

    $(".active-subsubmenu").css("display", "flex").animate(
        {
            opacity: 1,
        },
        0
    );

    $(this).attr("aria-expanded", "true");
});
// 'mouseenter click' both run on touch on mobile so these need to be separate -- if the logic above is run twice the submenus will open and immediately close on mobile

$parentLink.on("click", function (e) {
    if (screen.width <= 991) {
        e.preventDefault();
    }
});

$subParentLink.on("click", function (e) {
    if (screen.width <= 991) {
        e.preventDefault();
    }
});

function hideSubMenu() {
    $(".active-submenu").removeClass("active-submenu");
    $subMenu.animate(
        {
            opacity: 0,
        },
        0,
        function () {
            $(this).css("display", "none");
        }
    );
    $parentLink.attr("aria-expanded", "false");
}

function hideSubSubMenu() {
    $(".active-subsubmenu").removeClass("active-subsubmenu");
    $subSubMenu.animate(
        {
            opacity: 0,
        },
        0,
        function () {
            $(this).css("display", "none");
        }
    );

    $subParentLink.attr("aria-expanded", "false");
}

// animate out subnavs

$subMenu.on("mouseleave", function () {
    console.log("leave sub", $(this));
    hideSubMenu();
    hideSubSubMenu();
});

$subSubMenu.on("mouseleave", function () {
    console.log("leave sub sub", $(this));
    hideSubSubMenu();
});
// $navbarList.on('mouseleave', function() {
//     hideSubMenu();
// })

// $(document).on('click', function() {
//     hideSubMenu()
//     animateOutSearchInput();
// })

// mobile menu
let menuOpen = false;

function openMobileMenu() {
    $hamburgerBtn.addClass("is-active").attr("aria-expanded", "true");
    $navbarList.slideDown();
    menuOpen = true;
    makeNavBlue();
}

function closeMobileMenu() {
    $hamburgerBtn.removeClass("is-active").attr("aria-expanded", "false");
    $navbarList.slideUp();
    menuOpen = false;
}

$hamburgerBtn.on("click", function () {
    if (!menuOpen) {
        openMobileMenu();
    } else {
        closeMobileMenu();
    }
});

// if the window is resized this cleans up the ui of any actions the user performed on the mobile menu such as opening the mobile menu or the search input

$(window).on("resize", function () {
    if (screen.width > 991) {
        $navbarList.css("display", "flex");
        $navLogo.css("opacity", 1);
        $searchInput.css("display", "none");
    } else {
        closeMobileMenu();
    }
});

// when scrolling down the page the site header needs to be changed to blue as the transparent nav only looks good on top of a header image with a dark overlay

let scroll_pos = 0;

$(document).on("scroll", function () {
    scroll_pos = $(this).scrollTop();
    if (scroll_pos <= 11) {
        makeNavTransparent();
    } else {
        makeNavBlue();
    }
});

$siteHeader.on("mouseenter", function () {
    makeNavBlue();
});

$siteHeader.on("mouseleave", function () {
    scroll_pos <= 3 && makeNavTransparent();
});

///////////////////////////////////////////////////////////////// END TOP MENU

///////////////////////////////////////////////////////////////// SLIDERS

// key services slider
$(".key-services__inner").on("init", function (event, slick) {
    $("#key-services-wrapper").removeClass("hidden");
});

$(".key-services__inner").slick({
    infinite: false,
    draggable: false,
    variableWidth: false,
    speed: 400,
    slidesToShow: 2,
    appendArrows: $(".key-services__wrapper"),
    prevArrow:
        '<button class="key-services__button key-services__button--prev"><span class="sr-only">Previous</span><span class="visible-xs visible-sm visible-md"></span><i class="fal fa-angle-left"></i></button>',
    nextArrow:
        '<button class="key-services__button key-services__button--next"><span class="sr-only">Next</span><span class="visible-xs visible-sm visible-md"></span><i class="fal fa-angle-right"></i></button>',
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
            },
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
            },
        },
    ],
});

if ($(".key-services__wrapper").length) {
    $(".slick-slider").on("click", ".slick-slide", function (e) {
        e.stopPropagation();
        const index = $(this).data("slick-index");
        const target = $('.slick-slide[data-slick-index="' + index + '"]');
        if ($(".slick-slider").slick("slickCurrentSlide") !== index) {
            $(".slick-slider").slick("slickGoTo", index);
            $(".slick-slide").removeClass("active-slide");
            setTimeout(function () {
                target.addClass("active-slide");
            }, 400);
        }
    });
}

// product page - product image slider
$(".product-image-slider").slick({
    infinite: true,
    draggable: false,
    variableWidth: false,
    speed: 400,
    slidesToShow: 2,
    nextArrow:
        '<button class="product-image-slider__next-arrow"><span class="sr-only">Next</span><span class="visible-xs visible-sm visible-md"></span><i class="fal fa-angle-right"></i></button>',
});

///////////////////////////////////////////////////////////////// END SLIDERS

///////////////////////////////////////////////////////////////// CONTACT TAB

// on the right of the screen there's a contact tab with email/tel links - this animates it in and out on hover

const $contactTab = $(".contact-tab");

$contactTab.on("mouseenter", function () {
    $contactTab.animate({ right: 0 }, 200);
});
$contactTab.on("mouseleave", function () {
    $contactTab.animate({ right: -276 }, 200);
});

///////////////////////////////////////////////////////////////// END CONTACT TAB

///////////////////////////////////////////////////////////////// PRODUCTS PAGE

$(() => {
    ScrollReveal().reveal(".product-thumbnail", { duration: 2500 });
});

// photo viewer - product
const $mainImg = $("#main-product-img");
const $productSlideImg = $(".product-slide-img");

$productSlideImg.on("click", function () {
    let currentHref = $mainImg.attr("src");
    let newHref = $(this).attr("src");

    $(this).animate(
        {
            opacity: 0,
        },
        200
    );
    $mainImg.animate(
        {
            opacity: 0,
        },
        200,
        function () {
            $(this).attr("src", currentHref);
            $mainImg.attr("src", newHref);
        }
    );

    $(this).animate(
        {
            opacity: 1,
        },
        200
    );
    $mainImg.animate(
        {
            opacity: 1,
        },
        200
    );
});

///////////////////////////////////////////////////////////////// END PRODUCTS PAGE

// remove this code
$("#search").on("keypress", function (e) {
    if (e.which === 13) {
        window.location.pathname = "/search-results";
    }
});
